( function( $, window, document, undefined ) {

	hamburger = $('.hamburger');
	site_nav = $('.site-nav');

	hamburger.on('click', function() {
		navToggle();
	});

	function navToggle() {
		hamburger.toggleClass('is-active');

		if ( site_nav.hasClass('is-open') ) {
			closeNav();
		} else {
			openNav();
		}
	}

	function openNav() {
		site_nav.addClass('is-open');
	}

	function closeNav() {
		site_nav.removeClass('is-open');
	}

} )( jQuery, window, document );
( function( $, window, document, undefined ) {

	var images = document.querySelectorAll('.parallax');
	new simpleParallax(images, {
	    delay: 0,
	    orientation: 'down',
	    scale: 1.2,
	    overfow: true
	});

} )( jQuery, window, document );
( function( $, window, document, undefined ) {

	var header = $('.page-home').find('.site-header');
	var hamburger = $('.hamburger');

	$(window).on('scroll', function(){
		scrollTop = $(document).scrollTop();
		
		if ( scrollTop > 0 ) {
			header.addClass('sticky');
		} else {
			header.removeClass('sticky');
		}
	});

} )( jQuery, window, document );